import {
  createComputed,
  createRoot,
  createSignal,
  getOwner,
  Owner,
  runWithOwner,
} from "solid-js";
import { useLocation, useNavigate } from "@solidjs/router";

import EventEmitter, { Abort } from "@repo/utils/EventEmitter";
import Log from "@repo/utils/Log";
import UPath from "@repo/utils/UPath";
import UProxy from "@repo/utils/UProxy";
import Platform from "@repo/utils-client/Platform";

const [currentHistoryDepth, setCurrentHistoryDepth] = createRoot(() =>
  createSignal(0)
);

if (Platform.isClient)
  window.history.replaceState({ "AppRouter:depth": 0 }, "");

export declare namespace useRouter {
  type Options = {
    external?: (path: string) => boolean;
  };
}

export function useRouter(options?: useRouter.Options) {
  const location = useLocation<{ "AppRouter:depth": number }>();
  const navigate = useNavigate();

  createComputed(() => {
    if (typeof location.state?.["AppRouter:depth"] === "number")
      setCurrentHistoryDepth(location.state["AppRouter:depth"]);
  });

  const events = new EventEmitter<
    [
      ["push", string, Abort | void],
      ["replace", string, Abort | void],
      ["back", undefined, Abort | void],
      ["back.or", ".." | (string & {}), Abort | void],
    ]
  >();

  const router = UProxy.merge(
    UProxy.merge(location, { depth: currentHistoryDepth, on: events.on }),
    UProxy.deepShim(
      {
        push(path: string) {
          const resolved = UPath.resolve(path, location.pathname);
          if (options?.external?.(resolved))
            window.location.pathname = resolved;
          else {
            navigate(resolved, {
              state: { "AppRouter:depth": currentHistoryDepth() + 1 },
            });
          }
        },
        replace(path: string) {
          const resolved = UPath.resolve(path, location.pathname);
          if (options?.external?.(resolved)) window.location.replace(resolved);
          else
            navigate(resolved, {
              replace: true,
              state: { "AppRouter:depth": currentHistoryDepth() },
            });
        },
        back: Object.assign(
          () => {
            navigate(-1);
          },
          {
            or(defaultRoute: ".." | (string & {})) {
              if (window.history.length > 1) router.back();
              else router.replace(defaultRoute);
            },
          }
        ),
      },
      (original, path) =>
        (...args) => {
          // @ts-expect-error
          const e = events.emit(path.join("."), args[0]);
          const aborted = e.results.some((res) => res instanceof Abort);

          Log.withTrace`${
            aborted ? Log.style("background: #f00", "Aborted") : ""
          }${aborted ? " " : ""}${Log.fn(
            [Log.color("#8f8", "AppRouter"), ...path],
            args
          )}`;

          if (!aborted) return original(...args);
        }
    )
  );

  return router;
}

type AppRouter = AppRouter.Context & {
  external: useRouter.Options["external"];
  register(options?: {
    owner?: Owner | null;
    external?: useRouter.Options["external"];
  }): void;
};
declare namespace AppRouter {
  type Context = ReturnType<typeof useRouter>;
}

let registered: AppRouter.Context;
let external: useRouter.Options["external"];

const AppRouter: AppRouter = new Proxy(
  {
    get external() {
      return external;
    },
    register({ owner = getOwner(), external: _external } = {}) {
      if (!owner)
        throw new Error("AppRouter must be registered in a Router context.");
      runWithOwner(owner, () => {
        external = _external;
        registered = useRouter({ external: _external });
      });
    },
  } as AppRouter,
  {
    get(target: any, p) {
      if (p in target) return target[p];
      if (!registered)
        throw new Error("Attempted to use AppRouter before registration.");
      // Rename to AppRouter to make errors clearer
      const AppRouter: any = registered;
      return AppRouter[p];
    },
  }
);

export default AppRouter;
