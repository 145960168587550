import UString from "./UString.mts";
import UType from "./UType.mts";

namespace UPath {
  // export const projectRoot = new URL("../../../", import.meta.url).pathname;

  export function resolve(path: string, currentPath: string) {
    if (path.includes("://")) return path;

    const url = new URL(
      path,
      // Ensure base url always has single trailing slash. By default /path and
      // /path/ will behave differently, trailing slash has more desirable
      // behavior. `location.search` will be omitted when new URL is
      // constructed, no need to include.
      `file://${UString.beforeSuffix(currentPath, "/")}/`,
    );

    return `${
      // Ensure resulting path doesn't have a "/", this can happen when
      // navigating to ".." or ".", url matching in application code is easier
      // with single standard
      UString.beforeSuffix(url.pathname, "/") || "/"
    }${url.search}`;
  }

  export const escape = (path: string) =>
    path.replace("(", "\\(").replace(")", "\\)");

  export const normalize = (path: string) =>
    UString.trim.end(`/${path}`.replace(/\/+/g, "/"), "/") || "/";

  export const lastSegment = (path: string) =>
    path.slice(path.lastIndexOf("/") + 1);

  export namespace Array {
    export function toTree(pathArray: string[]) {
      const res: UType.Nested<{
        current: string;
        end?: string;
        children: Record<string, UType.Nested.Children>;
      }> = { current: "/", children: {} };
      for (const path of pathArray) {
        if (path === "/") res.end = path;

        const segments = UString.trim.start(path, "/").split("/");

        (function walk(i = 0, current = res) {
          const segment = segments[i];
          if (!segment) return;

          const next = (current.children[segment] ??= {
            current: `/${segments.slice(0, i + 1).join("/")}`,
            children: {},
          });
          if (!segments[i + 1]) {
            const existing = next.end;
            if (existing && existing !== path)
              throw new Error(
                `Duplicate paths with different values "${existing}" and "${path}"`,
              );
            return (next.end = path);
          }
          walk(i + 1, next);
        })();
      }
      return res;
    }
  }
}

export default UPath;
